import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProjectBanner from '../../components/projectBanner';

const PortfolioLockTrip = () => {
  const data = useStaticQuery(graphql`
    query {
      lockTrip: file(relativePath: { eq: "lockTrip.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lockTrip_Search: file(relativePath: { eq: "lockTrip_Search.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lockTrip_Hotels: file(relativePath: { eq: "lockTrip_Hotels.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lockTrip_Flights: file(relativePath: { eq: "lockTrip_Flights.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lockTrip_Booking: file(relativePath: { eq: "lockTrip_Booking.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout pageInfo={{ pageName: 'locktrip' }} noLayoutbanner>
      <SEO title="LockTrip | Projects" />
      <ProjectBanner bgColor="#488BF8" title="LockTrip" description="Cryptobased Travel booking site that cuts the middlemen and offers great savings on travel bookings" image={data.lockTrip.childImageSharp.fluid} />
      <TheProject />
      <TheSearch image={data.lockTrip_Search.childImageSharp.fluid} />
      <TheHotels image={data.lockTrip_Hotels.childImageSharp.fluid} />
      <TheFlights image={data.lockTrip_Flights.childImageSharp.fluid} />
      <TheBooking image={data.lockTrip_Booking.childImageSharp.fluid} />
    </Layout>
  );
};
export default PortfolioLockTrip;

const TheProject = () => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5">
        <Col xs="12" lg="4">
          <p className="text-left h4 mb-1 font-weight-bolder">The Project</p>
          <hr style={{ width: '100px' }} className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
        </Col>
        <Col xs="12" lg="8">
          <p style={{ width: '100%' }}>LockTrip is a blockchain-based travel marketplace that allows users to save 20-60% on their bookings by cutting out middlemen. We had the opportunity of building the user facing frontend of this wonderful platform.</p>
        </Col>
      </Row>
    </Container>
  );
};

const TheSearch = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5 align-items-md-center">
        <Col xs="12" lg="4">
          <p className="text-left h4 mb-1 font-weight-bolder">Search</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p style={{ width: '100%' }}>Locktrip provides a huge inventory of hotels, homes and flights, all of it starts from a simple search bar. We made sure that the search bar was the hero across the website hence easily accessible wherever they are on the website.</p>
        </Col>
        <Col xs="12" lg="8">
          <Img className="headshot w-100 ml-auto mr-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

const TheHotels = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5">
        <Col xs="12" lg="4">
          <p className="text-left h4 mb-1 font-weight-bolder">Hotels</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p className="w-100 ">Hotels search results presented hotels with gallery and highlight features, and the travellers could filter down their results based on several factors hence able to narrow down their search to hotels that match their requirement. Photos of hotels right on search results helped users to look at them for making up mind. </p>
        </Col>
        <Col xs="12" lg="8">
          <Img className="headshot w-100 ml-auto mr-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

const TheFlights = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5">
        <Col xs="12" lg="4">
          <p className="text-left h4 mb-1 font-weight-bolder">Flights</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p className="w-100 ">The intuitive flight search results provided a great deal of information for travellers to make their decision like stopovers, duration of flights, airline company and more. The filters make it quite easy for users to narrow down their search and find the best flights for the best price with ease.</p>
        </Col>
        <Col xs="12" lg="8">
          <Img className="headshot w-100 ml-auto mr-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

const TheBooking = ({ image }) => {
  return (
    <Container>
      <Row noGutters className="py-5 my-5">
        <Col xs="12" lg="4">
          <p className="text-left h4 mb-1 font-weight-bolder">Booking</p>
          <hr className="info-color mb-4 mt-0 d-inline-block mx-auto hr-styles" />
          <p className="w-100 ">Checkout process is critical for travel bookings. Travellers need to be made aware of the terms and conditions, reconfirm the booking details and also provide multiple payment methods for finalizing their booking. Well defined sections in the UI helped users to quickly go review their booking and complete their booking process</p>
        </Col>
        <Col xs="12" lg="8">
          <Img className="headshot w-100 ml-auto mr-auto d-block" fluid={image} alt="" />
        </Col>
      </Row>
    </Container>
  );
};
